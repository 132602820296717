import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import ContactComponent from '../../../components/contact/contactComponent';
import TeamComponent from '../../../components/team/TeamComponent';

function TeamTemplate({ cmsData }) {
  
  const teamData = cmsData?.team?.edges[0]?.node?.frontmatter ?? {}

  return (
      <div>
          <TeamComponent cmsData={teamData}/>
      </div>
  )
}

export default function Team() {
    return (
      <StaticQuery
        query={graphql`
          query {
            team: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "sv-team"}}}) {
              edges {
                node {
                  frontmatter {
                    title
                    icon {
                        childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    employees {
                        image {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        name
                        role
                        email
                        linkedin
                    }
                  }
                }
              }
            },
          }
        `}
        render={(data) => <TeamTemplate cmsData={data} />}
      />
    );
  }
  